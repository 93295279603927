<template>
  <div>
    <app-page-edit page-title="Update MJO" page-toolbar-title="Update MJO">

      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>
        <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <!-- <app-button :loading="loading" @click="updateData()" mdi-icon="mdi-content-save" title="Save"></app-button> -->
        <app-button @click="redirect('Administration.MJO.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableCloseBtn" @click="closeData()" :loading="loading_close_document" title="Closed Document" mdi-icon="mdi-book-check"></app-button>
      </template>

      <v-row>
        <v-col cols="12" lg="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field dense disabled v-model="mjo_header.document_no_" placeholder="Doc. No." label="Doc. No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="mjo_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2" type="date"></v-text-field>
                <v-select dense disabled v-model="mjo_header.company_id" :items="list_company" item-text="label" item-value="value" placeholder="Company ID" label="Company ID" readonly filled outlined hide-details class="mb-2"></v-select>
                <v-text-field dense disabled v-model="mjo_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-select disabled readonly filled :items="$app_options.mjo.priority" dense v-model="mjo_header.priority" placeholder="Prioritas" label="Prioritas" outlined hide-details class="mb-2"></v-select>
                <v-select disabled readonly filled :items="$app_options.mjo.job_type" dense v-model="mjo_header.job_type" placeholder="Tipe Pekerjaan" label="Tipe Pekerjaan" outlined hide-details class="mb-2"></v-select>
                <v-text-field disabled readonly filled type="datetime-local" dense v-model="mjo_header.damage_at" placeholder="Tanggal Kerusakan" label="Tanggal Kerusakan" outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-textarea disabled readonly filled dense v-model="mjo_header.problem_description" placeholder="Detil Masalah" label="Detil Masalah" outlined hide-details class="mb-2" height="100"></v-textarea>
          </card-expansion>
          <card-expansion title="Fixed Asset" class="mb-3" :isMinimize="true">
            <FixedAssetDetail :mjo_header="mjo_header"></FixedAssetDetail>
          </card-expansion>
          <card-expansion title="Images" class="mb-3" :isMinimize="true">
            <Images :mjo_header="mjo_header"></Images>
          </card-expansion>

          <card-expansion class="mb-3" :isMinimize="false" :hide-title="true" :no-padding="true">
            <template #title>
              <v-tabs v-model="tab">
                <v-tab>Identifikasi Masalah</v-tab>
                <v-tab>PIC</v-tab>
                <v-tab>Langkah Perbaikan</v-tab>
              </v-tabs>
            </template>

            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card elevation="1" flat outlined class="mb-3">
                    <v-toolbar dense elevation="0" flat>
                      <v-toolbar-title>Identifikasi Masalah</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon title="Edit" @click="controlEdit1 = true" v-if="controlEdit1 == false"><v-icon>mdi-pencil</v-icon></v-btn>
                      <v-btn icon title="Save" v-if="controlEdit1 == true" @click="updateData()" color="primary"><v-icon>mdi-content-save</v-icon></v-btn>
                      <v-btn icon title="Cancel" @click="controlEdit1 = false, getDetail()" v-if="controlEdit1 == true"><v-icon>mdi-close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-card :disabled="!controlEdit1" elevation="0">
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col>
                                <v-select :items="$app_options.mjo.priority" dense v-model="mjo_header.priority" placeholder="Prioritas" label="Prioritas" outlined hide-details class="mb-2"></v-select>
                                <v-select dense hide-details flat outlined class="mb-3" v-model="mjo_header.substatus" :items="substatuses" label="Sub Status"></v-select>
                                <v-select :items="$app_options.mjo.job_level" dense v-model="mjo_header.job_level" placeholder="Kategori Pekerjaan" label="Kategori Pekerjaan" outlined hide-details class="mb-3"></v-select>

                              </v-col>
                              <v-col>
                                <v-textarea dense hide-details flat outlined class="mb-3" rows="5" v-model="mjo_header.description" label="Deskripsi"></v-textarea>

                              </v-col>
                            </v-row>

                            <v-divider class="mb-3"></v-divider>
                            
                            <v-row>
                              <v-col>
                                <app-date-time type="datetime-local" dense v-model="mjo_header.start_work_at" placeholder="Tanggal Mulai Pekerjaan" label="Tanggal Mulai Pekerjaan" outlined hide-details class="mb-2"></app-date-time>
                              </v-col>
                              <v-col>
                                <app-date-time type="datetime-local" dense v-model="mjo_header.end_work_at" placeholder="Tanggal Selesai Pekerjaan" label="Tanggal Selesai Pekerjaan" outlined hide-details class="mb-2"></app-date-time>
                              </v-col>
                            </v-row>

                            <v-textarea dense hide-details flat outlined class="mb-3" rows="4" v-model="mjo_header.damage_analysis" label="Analisa Kerusakan"></v-textarea>

                            <v-divider class="mb-3"></v-divider>

                            <v-row>
                              <v-col>
                                <app-date-time type="datetime-local" dense v-model="mjo_header.sparepart_start_waiting_at" placeholder="Tanggal Mulai Tunggu Sparepart" label="Tanggal Mulai Tunggu Sparepart" outlined hide-details class="mb-2"></app-date-time>
                              </v-col>
                              <v-col>
                                <app-date-time type="datetime-local" dense v-model="mjo_header.sparepart_end_waiting_at" placeholder="Tanggal Mulai Tunggu Sparepart" label="Tanggal Mulai Tunggu Sparepart" outlined hide-details class="mb-2"></app-date-time>
                              </v-col>
                            </v-row>
                            <client-only>
                              <label for="">Sparepart Needed:</label>
                              <vue-editor outlined v-model="mjo_header.sparepart_needed" class="mb-6" />
                            </client-only>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <SetPIC :id="parseInt($route.query.id)"></SetPIC>
                </v-tab-item>
                <v-tab-item>
                  <CorrectiveAction :id="parseInt($route.query.id)"></CorrectiveAction>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </card-expansion>

          <card-expansion title="Approval" class="mb-3" :isMinimize="true" no-padding>
            <ApprovalList v-bind:data-list="mjo_approval" :hideToolbar="true"></ApprovalList>
          </card-expansion>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :mjo_header="mjo_header"></document-info>
          <app-comment :document_id="parseInt(mjo_header.id)" :source_document="mjo_header.source_document" :document_no_="mjo_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-edit>


    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />

    <FindFADialog ref="refFindFADialog" @callback="getSelectedItem"></FindFADialog>
  </div>
</template>

<script>
import FindFADialog from './components/FindFADialog.vue';
import ApprovalList from "./components/ApprovalList.vue";
import CorrectiveAction from "./components/CorrectiveAction.vue";
import SetPIC from "./components/SetPIC.vue";
import DocumentInfo from './components/DocumentInfo.vue';
import Images from './components/Images.vue';
import FixedAssetDetail from './components/FixedAssetDetail.vue';
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    FindFADialog,
    ApprovalList,
    CorrectiveAction,
    SetPIC,
    DocumentInfo,
    Images,
    FixedAssetDetail,
    VueEditor,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,
      modal1: false,
      modal2: false,
      tab: null,
      dialogDescAdd: false,
      dialogDescEdit: false,
      saveControl: false,
      search: '',

      list_company: [
        { label: 'SBU PRODUCTION', value: 'SBUP' },
        { label: 'SBU SALES', value: 'SBUS' },
      ],

      selected_fa: {},
      fa_loading: false,
      fa_list: [],
      mjo_header: {
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        image5: '',
      },
      mjo_approval: [],
      mjo_comment: [],
      mjo_pic: [],
      selected_image: 0,

      controlEdit1: false,
      substatuses: [
        'received',
        'on-progress',
        'waiting-sparepart',
        'pending',
        'done',
      ],

      loading_close: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    mjo_header(n, o) {
      this.saveControl = true;

      if (typeof n !== 'undefined') {
        if (n.status == 'closed') {
          this.redirect("Administration.MJO.Index");
        }
      }
    },

    search(val) {
      // Items have already been loaded
      // if (this.fa_list.length > 0) return

      // Items have already been requested
      if (this.fa_loading) return

      this.fa_loading = true

      this.findFixedAsset(val)
    },


  },
  computed: {
    showImage() {

      if (this.mjo_header.image1.length == 0 &&
        this.mjo_header.image2.length == 0 &&
        this.mjo_header.image3.length == 0 &&
        this.mjo_header.image4.length == 0)
        return false;

      return true;
    },

    enableCloseBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "close")) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async getDetail(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "mjo/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.mjo_header = res.data.data.mjo_header;
          this.mjo_approval = res.data.data.mjo_approval;
          this.mjo_comment = res.data.data.mjo_comment;
          this.mjo_pic = res.data.data.mjo_pic;
          this.user = res.data.data.user;

          this.selected_fa.no_ = this.mjo_header.fa_no_;
          this.selected_fa.description = this.mjo_header.fa_description;
          this.selected_fa.description2 = this.mjo_header.fa_description2;
          this.selected_fa.fa_subclass_code = this.mjo_header.fa_fa_subclass_code;
          this.selected_fa.area_code = this.mjo_header.fa_area_code;
          this.selected_fa.location_code = this.mjo_header.fa_location_code;
          this.selected_fa.fa_location_code = this.mjo_header.fa_fa_location_code;
          this.selected_fa.responsibility = this.mjo_header.fa_responsibility;
          this.selected_fa.serial_no_ = this.mjo_header.fa_serial_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    updateData() {
      let post = async () => {
        this.loading_receive = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);

        formData.append("substatus", this.mjo_header.substatus);
        formData.append("description", this.mjo_header.description);
        formData.append("damage_analysis", this.mjo_header.damage_analysis);
        formData.append("start_work_at", this.mjo_header.start_work_at);
        formData.append("end_work_at", this.mjo_header.end_work_at);
        formData.append("sparepart_start_waiting_at", this.mjo_header.sparepart_start_waiting_at);
        formData.append("sparepart_end_waiting_at", this.mjo_header.sparepart_end_waiting_at);
        formData.append("job_level", this.mjo_header.job_level);
        formData.append("priority", this.mjo_header.priority);
        await this.$axios
          .post("mjo/update", formData)
          .then((res) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getDetail(true);

            this.controlEdit1 = false;
          })
          .catch((error) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Update?", post);
    },

    uploadFile(sa) {
      this.selected_image = sa;
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      if (this.selected_image == 1) {
        this.mjo_header.image1 = "";
      } else if (this.selected_image == 2) {
        this.mjo_header.image2 = "";
      } else if (this.selected_image == 3) {
        this.mjo_header.image3 = "";
      } else if (this.selected_image == 4) {
        this.mjo_header.image4 = "";
      }

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("mjo/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            if (this.selected_image == 1) {
              this.mjo_header.image1 = resData.data.file_src;
            } else if (this.selected_image == 2) {
              this.mjo_header.image2 = resData.data.file_src;
            } else if (this.selected_image == 3) {
              this.mjo_header.image3 = resData.data.file_src;
            } else if (this.selected_image == 4) {
              this.mjo_header.image4 = resData.data.file_src;
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async findFixedAsset(search) {
      this.fa_loading = true;
      this.notFound = false;

      var config = {
        params: {
          search: search,
        },
      };
      await this.$axios
        .get("mjo/find-fixed-asset", config)
        .then((res) => {
          this.fa_list = res.data.data.results;

          this.fa_loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.fa_loading = false;
          this.axiosErrorHandler(error);
        });
    },

    getSelectedItem(selected_fa) {
      this.selected_fa = selected_fa
    },
    /**
     * closeData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    closeData() {
      let post = async () => {
        this.loading_close = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/close", formData)
          .then((res) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.redirect('Administration.MJO.Detail', { id: this.mjo_header.id })
          })
          .catch((error) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Close document?", post);
    },
  },
  mounted() {
    this.id = this.$route.query.id;

    this.getDetail();
  },
};
</script>
