<template>
  <div>
    <v-card outlined elevation="1" min-height="420" :loading="loading">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>PIC</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="getData()" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="d-flex">
        <v-autocomplete dense autofocus outlined class="mr-2" placeholder="Search" v-model="selected_pic" return-object :items="source_lists" item-text="full_name" item-value="emp_id" style="max-width: 300px" hide-details single-line>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-img cover :src="'https://sflib.dataon.com/cliimg/polagroup/upload/emp_photos/202003/' + data.item.emp_id + '_22020.JPG'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.item.emp_id }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ data.item.pos_name_eng }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-btn @click="saveData()">Add</v-btn>
        <v-btn @click="getListPIC()">Reload</v-btn>
      </v-card-text>

      <v-list two-line>
        <v-list-item v-for="pic in pic_list" :key="pic.id">
          <v-list-item-avatar size="60">
            <v-img cover :src="'https://sflib.dataon.com/cliimg/polagroup/upload/emp_photos/202003/' + pic.emp_id + '_22020.JPG'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ pic.full_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ pic.emp_id }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ pic.pos_name_eng }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="deleteData(pic)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      search: "",
      selected_pic: {},
      pic_list: [],
      loading: false,
      source_lists: []
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      const uri = "mjo/pic/list";
      let params = {
        mjo_header_id: this.id,
      }
      await this.$axios
        .get(uri, {
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.pic_list = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
    async getListPIC() {
      this.loading = true;
      const uri = "mjo/find-pic";
      await this.$axios
        .get(uri)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.source_lists = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
    async saveData() {
      this.loading = true;

      let data = {
        mjo_header_id: this.id,
        employee_id: this.selected_pic.id
      }

      await this.$axios
        .post("mjo/pic/save", this.objectToFormData(data))
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }

          this.getData();

          this.selected_pic = {}
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });


      this.loading = false;
    },
    deleteData(item) {
      let post = async () => {
        this.loading = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios
          .post("mjo/pic/delete", formData)
          .then((res) => {
            this.loading = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getData();
          })
          .catch((error) => {
            this.loading = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);

            this.getData();
          });
      }
      this.showConfirm("Confirm", "Delete Data?", post);
    },
  },

  mounted() {
    this.getData();
    this.getListPIC();
  }
}
</script>